module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-cnzz/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1279194001","head":false},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"example","short_name":"example","start_url":"/","background_color":"#FF9200","theme_color":"#FF9200","display":"minimal-ui","icon":"/builds/cps/openapi-frontend/gatsby-theme-antv/site/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a43e9efb9906e2f04d35a8280ae58fc2"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/cps/openapi-frontend/gatsby-theme-antv/site/layouts/layout.tsx"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#FF9200"},
    },{
      plugin: require('../../gatsby-theme-antv/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"primary-color":"#FF9200"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

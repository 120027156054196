import React from 'react';
import styles from './CpsFooter.module.less';
import lianxi from '../assets/lianxi.png';
import intro from '../assets/intro.png';
import download from '../assets/download.png';
import wx from '../assets/wx.png';
import wxcode from '../assets/wxcode.png';
import { useTranslation } from 'react-i18next';

const CpsFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_describe}>
        <div className={styles.footer_describe_item}>
          <img src={intro} className={styles.footer_icon} alt="" />
          <div>
            <p>{t('关于联盟')}</p>
            <p
              style={{ cursor: 'pointer' }}
              className={styles.footer_intro}
              onClick={() => {
                window._czc.push([
                  '_trackEvent',
                  'home_footer_discribe',
                  'click',
                ]);
                go(`/support/70/118419`);
              }}
            >
              {t('联盟介绍')}
            </p>
            <a
              href="https://weibo.com/duomaicps"
              rel="noopener noreferrer"
              onClick={() =>
                window._czc.push(['_trackEvent', 'home_footer_weibo', 'click'])
              }
            >
              {t('官方微博')}
            </a>
          </div>
        </div>
        <div className={styles.footer_describe_item}>
          <img src={download} className={styles.footer_icon} alt="" />
          <div>
            <p>{t('APP下载')}</p>
            <a
              href="http://dwz.win/ufQ"
              rel="noopener noreferrer"
              onClick={() =>
                window._czc.push([
                  '_trackEvent',
                  'home_footer_Android',
                  'click',
                ])
              }
            >
              {t('Android下载')}
            </a>
            <a
              href="http://dwz.win/ufE"
              rel="noopener noreferrer"
              onClick={() =>
                window._czc.push(['_trackEvent', 'home_footer_iOS', 'click'])
              }
            >
              {t('iOS下载')}
            </a>
          </div>
        </div>
        <div className={styles.footer_describe_item}>
          <img src={lianxi} className={styles.footer_icon} alt="" />
          <div>
            <p>{t('流量主联系')}</p>
            <p className={styles.contact_tel}>0571-56638988</p>
          </div>
        </div>
        <div className={styles.footer_describe_item}>
          <img src={wx} className={styles.footer_icon} alt="" />
          <div>
            <p>{t('官方微信')}</p>
            <img src={wxcode} className={styles.wx_qrcode} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.footer_line} />
      <div className={styles.footer_copyright}>
        <p>
          {t('多麦广告联盟')} Copyright © 2014-2019{' '}
          {t('杭州多麦电子商务股份有限公司')} All Rights Reserved
        </p>
        <p>
          增值电信业务经营许可证编号:浙B2-20110112 备案信息:
          <a
            href="http://www.beian.miit.gov.cn"
            style={{ color: '#8a8d99' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            浙ICP备09012127 号-2
          </a>
        </p>
        <p>
          浙公网安备{' '}
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010402000150"
            style={{ color: '#8a8d99' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            33010402000150 号
          </a>
        </p>
      </div>
    </footer>
  );
};

export default CpsFooter;
